<template>
  <div>
    <h4 class="pl-2 font-weight-bold">システムパラメータ設定</h4>
    <!-- Filter Search -->
    <div class="top-event d-flex flex-column flex-md-column">
      <b-button
        class="d-block d-md-none mx-0 btn-toggle mb-2 mx-md-2"
        v-b-toggle.event-filter
        >絞り込む</b-button
      >
      <b-collapse id="event-filter" class="w-100 d-md-block">
        <b-row class="filter-search justify-content-end">
          <b-col cols="12" sm="6" md="3" lg="3">&nbsp;</b-col>
          <b-col cols="12" sm="6" md="3" lg="3">&nbsp;</b-col>
          <b-col cols="12" sm="6" md="3" lg="3">&nbsp;</b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-form-input
              class="name-event mb-2 mb-md-3 text-truncate"
              v-model="filterAll"
              debounce="500"
              placeholder="フリーワード"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
    <!-- Main table element -->
    <b-table
      :items="filtered"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filterAll"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :show-empty="hideBeforeLoading"
      small
      stacked="md"
      @filtered="onFiltered"
      responsive="sm"
      bordered
      ref="table-data"
    >
      <template #top-row="row">
        <b-th v-for="(col, index) in row.fields" :key="index">
          <b-form-input
            v-model="filters[col.key]"
            v-if="col.key !== 'id'"
            type="search"
            :placeholder="col.label"
            debounce="500"
          ></b-form-input>
        </b-th>
      </template>

      <template #cell(name)="row">
        {{ row.item.name }}
      </template>
      <template #cell(value)="row">
        <b-badge variant="warning" class="py-2">
          {{ formatPoint(row.item.value) }}</b-badge
        >
      </template>

      <template #cell(id)="row">
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="infoModal(row.item)"
          ><b-badge variant="primary" class="py-2">更新</b-badge
          ><esports-loading-button
            v-if="row.item.systemParamId === configInfo.systemParamId && action_type === 'edit'"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></span>
      </template>

      <template #empty>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
      <template #emptyfiltered>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
    </b-table>

    <esports-paging 
      :items="items"
      :limit="limit"
      :totalRows="totalRows"
      :isResult="isResult">
      <template v-slot:perpage>
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
        ></b-form-select>
      </template>
      <template v-slot:paging>
        <template v-if="isResult && totalRows > perPage">
          <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          class="my-0 ml-3"
        ></b-pagination>
        </template>
      </template>
    </esports-paging>

    <!-- create/update Game Modal -->
    <b-modal
      :id="configInfo.id"
      :title="configInfo.title_modal"
      @hide="resetconfigInfo"
      @shown="handleUpdateCard"
      ref="card-info-modal"
    >
      <div class="form-modal">
        <transition-alert>
          <template v-if="msgErrors.length">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="danger"
            >
              <span
                class="d-block"
                v-for="(msg, index) in msgErrors"
                :key="index"
                >{{ msg }}</span
              >
            </b-alert>
          </template>
        </transition-alert>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
        </transition-alert>
        <b-form-group class="mb-2">
          <label>コード</label>
          <b-form-input
            v-model="configInfo.title"
            type="text"
            :state="ckStateGame('title')"
            disabled
          ></b-form-input>
          <b-form-invalid-feedback
            >コードは必須です。</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group class="mb-2">
          <label>値</label>
          <b-form-input
            v-model.number="configInfo.numberPoints"
            type="number"
            min="1"
            :state="ckStateGame('numberPoints')"
            :formatter="formatter"
          ></b-form-input>
          <b-form-invalid-feedback
            >値は必須です。</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group class="mb-2">
          <label>説明</label>
          <b-form-textarea
            v-model="configInfo.description"
            rows="3"
            no-resize
          ></b-form-textarea>
          <b-form-invalid-feedback
            >説明は必須です。</b-form-invalid-feedback
          >
        </b-form-group>
      </div>
      <template #modal-footer="{ close }">
        <b-button
          class="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="onSubmitEvent(close)"
          >{{ systemParamId ? "更新" : "登録" }}
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { dismissCount } from "@/mixins";
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';

const greaterThanZero = (value) => value > 0;

export default {
  name: "SettingsSys",
  data() {
    return {
      isSubmitted: false,
      fromDate: "",
      toDate: "",
      systemParamId: "",
      items: [],
      fields: [
        {
          key: "code",
          label: "コード",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "value",
          label: "値",
          sortable: true,
        },
        {
          key: "description",
          label: "説明",
          sortable: true,
          sortDirection: "desc",
        },
        { key: "id", label: "" },
      ],
      filters: {
        code: "",
        value: "",
        description: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 15, 20, { value: 100, text: "すべて" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterAll: null,
      filterOn: ["code", "value", "description"],
      configInfo: {
        id: "info-modal",
        title_modal: "",
        title: "",
        numberPoints: "",
        description: ""
      },
      cardTemp: null,
      msgErrors: [],
      msgSuccess: null,
      action_type: "",
      isEnableCloseModal: false,
      limit: 0,
      isDetailRow: false
    };
  },
  mixins: [validationMixin, dismissCount],
  validations() {
    if (this.systemParamId) {
      return {
        configInfo: {
          title: { required },
          numberPoints: { required, maxValue: greaterThanZero },
        },
      };
    }
    return {
      configInfo: {
        title: { required },
        numberPoints: { required, maxValue: greaterThanZero },
      },
    };
  },
  watch: {
    perPage() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
      });
    },
    currentPage(page) {
      this.$nextTick(() => {
        let currentItems = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
        this.limit = this.perPage * (page - 1) + currentItems;
      });
    },
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    msgSuccess(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    filtered(nVal) {
      this.$nextTick(() => {
        this.currentPage = this.isDetailRow ? this.currentPage : 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
        this.totalRows = nVal.length;
      });
    },
  },
  computed: {
    isValidCard() {
      return !this.$v.configInfo.$anyError;
    },
    isResult() {
      return this.items.length;
    },
    filtered() {
      let filtered = this.items.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key])
            ?.toLowerCase()
            .includes(this.filters[key]?.toLowerCase())
        );
      });

      return filtered.length > 0 ? filtered : [];
    },
  },
  async mounted() {
    this.setLazyLoading(true);
    await Promise.all([this.getParamsSysAll()]);
    this.hideBeforeLoading = true;

    this.cardTemp = { ...this.configInfo };

    if (this.items.length > this.perPage) {
      this.limit = this.perPage;
    } else {
      this.limit = this.items.length;
    }
  },
  methods: {
    handleUpdateCard() {
      this.cardTemp = { ...this.configInfo };
    },
    showModalConfirmCloseCard() {
      this.$bvModal
        .msgBoxConfirm("本当にキャンセルしますか？", {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2 footer-mgs",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isEnableCloseModal = true;
            this.$refs["card-info-modal"].hide();
          }
        });
    },
    async getParamsSysAll(params) {
      const result = await this.$store
        .dispatch("adminSysParams/getParamsSysAll", params)
        .catch((err) => {
          this.setLazyLoading(false);
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.items = result.map((player, index) => {
          player.picture = `${process.env.VUE_APP_BACKEND + player.picture}`;

          if (player.createdDate) {
            player.createdDate = moment(player.createdDate).format(
              "YYYY-MM-DD"
            );
          }

          return player;
        });

        this.items = orderBy(this.items, ['createdDate'], ['desc']);

        this.totalRows = this.items.length;
        this.setLazyLoading(false);
      }
    },
    async handleFilterEvents() {
      let params = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      if (!params.fromDate) delete params.fromDate;
      if (!params.toDate) delete params.toDate;

      await this.getParamsSysAll(params);
    },
    async onSubmitEvent(close) {
      if (this.validate()) {
        let params = {
          file: this.configInfo.file,
          configInfo: "",
          systemParamId: this.systemParamId,
        };

        let configInfo = {
          value: this.configInfo.numberPoints,
          description: this.configInfo.description
        };

        if (this.systemParamId) {
          configInfo.systemParamId = this.systemParamId;
        }

        params.configInfo = JSON.stringify(configInfo);

        this.showMsgConfirmCreate(() => {
          this.isEnableCloseModal = true;
          this.upSertCard(configInfo, close);
        });
      }
    },
    async upSertCard(params, close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("adminSysParams/updateParamSys", params)
        .catch((err) => {
          this.isSubmitted = false;

          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      if (result) {
        this.reloadListCard(result, close);
      }
    },
    showMsgConfirmCreate(cb) {
      let msg = this.systemParamId
        ? "更新してもよろしいですか？"
        : "登録してもよろしいですか？";

      this.$bvModal
        .msgBoxConfirm(msg, {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value === null) return;

          if (value) {
            if (cb) cb();
            return;
          }

          this.isEnableCloseModal = true;
          this.$refs["card-info-modal"].hide();
        });
    },
    async infoModal(item) {
      if (!item) {
        this.systemParamId = "";
        this.configInfo.title_modal = `新規作成`;
        this.configInfo.id = "info-modal";
        this.configInfo.systemParamId = "";

        this.cardTemp.title_modal = `新規作成`;
        this.cardTemp.id = "info-modal";
        this.cardTemp.systemParamId = "";

        this.$root.$emit("bv::show::modal", this.configInfo.id);
        return;
      }
      this.configInfo.title_modal = `更新`;
      this.configInfo.systemParamId = item.systemParamId;
      this.action_type = "edit";

      const result = await this.$store
        .dispatch("adminSysParams/getDetailParamSys", item.systemParamId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        this.loadDataInfo(item, result);

        this.$root.$emit("bv::show::modal", this.configInfo.id);
      }
    },
    async resetFilter() {
      this.filterAll = "";
      this.filters.code = "";
      this.filters.description = "";
      this.filters.value = "";

      this.fromDate = null;
      this.toDate = null;
      await this.handleFilterEvents();
    },
    async reloadListCard(result, close) {
      this.msgSuccess = result.message;
      this.msgErrors = [];
      this.isSubmitted = false;
      this.resetData();
      this.handleSuccessResp(close);
      await this.handleFilterEvents();
    },
    validate() {
      this.$v.$touch();
      return this.isValidCard;
    },
    ckStateGame(val) {
      let field = this.$v.configInfo[val];
      return !field.$dirty || !field.$invalid;
    },
    resetData() {
      this.page = 1;
      this.systemParamId = "";
      this.fromDate = "";
      this.toDate = "";
      this.perPage = 20;
    },
    resetconfigInfo(data) {
      if (
        !isEqual(this.configInfo, this.cardTemp) &&
        !this.isEnableCloseModal
      ) {
        this.showModalConfirmCloseCard();
        data.preventDefault();
        return;
      }

      this.configInfo.title_modal = "";
      this.configInfo.title = "";
      this.configInfo.numberPoints = "";
      this.action_type = "";
      this.msgErrors = [];

      this.isEnableCloseModal = false;

      this.$v.$reset();
    },
    onFiltered() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
      });
    },
    handleSuccessResp(close) {
      setTimeout(() => {
        close();
        this.msgSuccess = null;
        this.dismissCountDown = 0;
      }, 2000);
    },
    loadDataInfo(item, result) {
      this.configInfo.systemParamId = "";
      this.configInfo.title = result.code;
      this.configInfo.numberPoints = result.value;
      this.configInfo.description = result.description;
      this.systemParamId = item.systemParamId;
    },
    formatter(value) {
      if (parseInt(value, 10) === 0) return 1;

      return value !== "" ? +value : "";
    },
  },
};
</script>